import React from 'react';
import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';
import { Router } from '@wix/tpa-router/react';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { PortalProvider } from 'wui/Portal/context';
import { ErrorState } from 'wui/ErrorState';

import { ControllerProvider } from 'common/context/controller';
import type { IControllerProps } from 'controller/types';

import { Toasts } from '../Toasts';
import { GroupMembershipDialogs } from '../GroupMembership';

interface ISocialGroupsAppProps extends WidgetProps<IControllerProps> {
  children: React.ReactNode;
}

export function SocialGroupsApp(props: ISocialGroupsAppProps) {
  const { children, store, ...rest } = props;
  const { t } = useTranslation();
  const { isRTL } = useEnvironment();

  return (
    <div data-hook="social-groups-app" dir={isRTL ? 'rtl' : 'ltr'}>
      <ErrorMonitorBoundary
        isDebug
        fallback={
          <ErrorState
            title={t('groups-web.error-state.title')}
            subtitle={t('groups-web.error-state.subtitle')}
            actionLabel={t('groups-web.error-state.retry.label')}
          />
        }
      >
        <SocialGroupStore state={store}>
          <Router {...rest}>
            <PortalProvider host={props.host}>
              <ControllerProvider {...rest}>
                <WixCommentsApiProvider {...rest}>
                  {children}
                  <Toasts />
                  <GroupMembershipDialogs />
                </WixCommentsApiProvider>
              </ControllerProvider>
            </PortalProvider>
          </Router>
        </SocialGroupStore>
      </ErrorMonitorBoundary>
    </div>
  );
}

SocialGroupsApp.displayName = 'SocialGroupsApp';
